import axios from "axios";
import { getServersideSettings } from "config";
import { getAuthData, type AuthData } from "models/AuthModel";
import type { TUser } from "models/UserModel";
import { sleep } from "modules/misc";

const { serversideSettings, baseUrl } = getServersideSettings();

export const apiAxiosOptions = () => {
  const authData = getAuthData();
  return {
    withCredentials: true,
    headers: { "X-CSRF-Token": authData ? authData.csrf_token : null },
  };
};

export const apiSignIn = async (userName: any, password: any) => {
  const result = await axios
    .post(
      `${baseUrl}user/login?_format=json`,
      {
        name: userName,
        pass: password,
      },
      apiAxiosOptions(),
    )
    .then((response) => {
      return {
        status: "success",
        response,
      };
    })
    .catch((error) => {
      return {
        status: "fail",
        response: error.response,
      };
    });
  return result;
};

export const apiSignOut = async (authData: AuthData) => {
  const token = authData && authData.logout_token;
  const result = await axios
    .post(`${baseUrl}user/logout?_format=json&token=${token}`, {}, apiAxiosOptions())
    .then((response) => {
      return {
        status: "success",
        response,
      };
    })
    .catch((error) => {
      console.log(error);
      return {
        status: "fail",
        response: error.response,
      };
    });
  return result;
};

export const apiFetchUser = async () => {
  const authData = getAuthData();
  const uid = authData && authData.current_user.uid;
  if (!uid) return null;
  const result = await axios
    .get(`${baseUrl}user/${uid}?_format=json`, apiAxiosOptions())
    .then(async (response) => {
      await sleep(0);
      const user = apiBuildUser(response.data);
      return { status: "success", raw: response.data, user };
    })
    .catch((error) => {
      console.log(error);
      return { status: "fail", raw: error.response, user: null };
    });
  return result;
};

export const apiBuildUser = (data: any): TUser => {
  const menus = data.field_cp_menus_json[0] ? data.field_cp_menus_json[0]["value"] : false;
  const routes = data.field_cp_routes_json[0] ? data.field_cp_routes_json[0]["value"] : false;
  const cards = data.field_cp_cards_json[0] ? data.field_cp_cards_json[0]["value"] : false;
  const layouts = data.field_cp_layouts_json[0] ? data.field_cp_layouts_json[0]["value"] : false;
  return {
    uid: data.uid["value"],
    menus: menus ? JSON.parse(menus) : [],
    routes: routes ? JSON.parse(routes) : [],
    cards: cards ? JSON.parse(cards) : [],
    layouts: cards ? JSON.parse(layouts) : {},
    firstName: data.field_first_name[0] ? data.field_first_name[0]["value"] : null,
    lastName: data.field_last_name[0] ? data.field_last_name[0]["value"] : null,
    avatar_file_id: data.user_picture[0] ? data.user_picture[0]["target_id"] : null,
    avatar_url: data.user_picture[0] ? data.user_picture[0]["url"] : null,
  };
};

const apiFileUpload = async (url: string, file: File) => {
  const options = JSON.parse(JSON.stringify(apiAxiosOptions()));
  const fileName = encodeURI(file.name);
  options.headers["Content-Type"] = "application/octet-stream";
  options.headers["Content-Disposition"] = `file; filename="${fileName}"`;
  const result = await axios
    .post(`${baseUrl}${url}?_format=json`, file, options)
    .then((response) => {
      const data = response.data;
      data["file_id"] = data["fid"][0]["value"];
      data["url"] = `${serversideSettings.schema}://${serversideSettings.host}${data["uri"][0]["url"]}`;
      return {
        status: "success",
        data,
      };
    })
    .catch((error) => {
      return {
        status: "fail",
        response: error.response,
      };
    });
  return result;
};

export const apiUploadUserAvatar = async (file: File) => {
  const result = await apiFileUpload("file/upload/user_entity/user/user/user_picture", file);
  return result;
};

export const apiPatchUser = async (orgData: any, newData: any) => {
  const authData = getAuthData();
  if (!authData) return;

  const editedData = { ...orgData };

  Object.keys(newData).forEach((key) => {
    switch (key) {
      case "cards":
        editedData["field_cp_cards_json"] = [{ value: JSON.stringify(newData[key]) }];
        break;
      case "layouts":
        editedData["field_cp_layouts_json"] = [{ value: JSON.stringify(newData[key]) }];
        break;
      case "avatar_file_id":
        editedData["user_picture"] = [{ target_id: newData[key] }];
        break;
      default:
        editedData[`field_${key}`] = [{ value: newData[key] }];
        break;
    }
  });

  delete editedData.changed;
  const result = await axios
    .patch(`${baseUrl}user/${editedData.uid[0]["value"]}?_format=json`, editedData, apiAxiosOptions())
    .then((response) => {
      return {
        status: "success",
        response,
      };
    })
    .catch((error) => {
      return {
        status: "fail",
        response: error.response,
      };
    });
  return result;
};

export const apiFetchCardMasters = async () => {
  const result = await axios.get(`${baseUrl}api/card-masters`, apiAxiosOptions()).then(async (response) => {
    await sleep(0);
    return response.data;
  });
  return result;
};

export const apiFetchSearchMasters = async () => {
  const result = await axios.get(`${baseUrl}api/search-masters`, apiAxiosOptions()).then(async (response) => {
    await sleep(0);
    return response.data;
  });
  return result;
};
