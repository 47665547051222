import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { arrayMove } from "@dnd-kit/sortable";
import { allMenusSelector, TMenu } from "models/MenuModel";
import { allRoutesSelector, TRoute, useRouteStates } from "models/RouteModel";

const leftNavOpenState = atom<boolean>({
  key: "leftNavOpen",
  default: true,
});

export const useLeftNavOpen = () => {
  const setState = useSetRecoilState(leftNavOpenState);

  const isOpen = useRecoilValue(leftNavOpenState);
  const setOpen = () => setState(true);
  const setClose = () => setState(false);
  const setToggle = () => setState(!isOpen);

  return { isOpen, setOpen, setClose, setToggle };
};

type TLeftNavItem = TRoute | TMenu;

const leftNavItemState = atomFamily<TLeftNavItem, number>({
  key: "leftNavItemState",
  default: undefined,
});

const leftNavItemIdsState = atom<number[]>({
  key: "leftNavItemIdsState",
  default: selector({
    key: "leftNavItemIdsStateDefault",
    get: ({ get }) => {
      const result: number[] = [];
      get(allMenusSelector).forEach((menu) => {
        result.push(menu.id);
        get(allRoutesSelector)
          .filter((route) => route.menuId === menu.id && !route.depth)
          .forEach((route) => result.push(route.id));
      });
      return result;
    },
  }),
});

const leftNavItemSelector = selectorFamily<TLeftNavItem, number>({
  key: "leftNavItemSelector",
  get:
    (id) =>
    ({ get }) => {
      const item = get(leftNavItemState(id));
      if (item) return item;
      const menus = get(allMenusSelector);
      const routes = get(allRoutesSelector);
      const newItem = [...menus, ...routes].find((item) => item.id === id);
      return newItem ? newItem : ({} as TLeftNavItem);
    },
  set:
    (id) =>
    ({ set }, item) => {
      set(leftNavItemState(id), item);
    },
});

const leftNavItemsSelector = selector<TLeftNavItem[]>({
  key: "allLeftNavItemsSelector",
  get: ({ get }) => {
    const ids = get(leftNavItemIdsState);
    return ids.map((id) => get(leftNavItemSelector(id)));
  },
  set: ({ set }, items) => {
    if (items instanceof DefaultValue) return;
    items.forEach((item) => set(leftNavItemState(item.id), item));
  },
});

const useLeftNavItem = () => {
  const items = useRecoilValue(leftNavItemsSelector);

  const setItem = useRecoilCallback(({ set }) => (item: TLeftNavItem) => {
    set(leftNavItemState(item.id), item);
  });

  const resetItem = useRecoilCallback(({ reset }) => (item: TLeftNavItem) => {
    reset(leftNavItemState(item.id));
  });

  return { items, setItem, resetItem };
};

const useLeftNavItemIds = () => {
  const ids = useRecoilValue(leftNavItemIdsState);

  const setIds = useRecoilCallback(({ set }) => (ids: Array<TLeftNavItem["id"]>) => {
    set(leftNavItemIdsState, ids);
  });

  return { ids, setIds };
};

const idsArrayMove = (ids: any[], activeId: number, overId: number) => {
  const oldIndex = ids.findIndex((id) => id === activeId);
  const newIndex = ids.findIndex((id) => id === overId);
  return arrayMove(ids, oldIndex, newIndex);
};

export const useLeftNavItems = () => {
  const { items, setItem, resetItem } = useLeftNavItem();
  const { ids: itemIds, setIds: setItemIds } = useLeftNavItemIds();
  const { isOpen, setOpen, setClose } = useLeftNavOpenFolders();
  const { allRoutes: routes, routeIds, setRoute, setRouteIds } = useRouteStates();

  const setItemsOnDragEnd = (activeId: number, overId: number) => {
    const activeItem = items.find((item) => item.id === activeId) as TRoute;
    setRoute(activeItem);
    resetItem(activeItem);
    setRouteIds(idsArrayMove(routeIds, activeId, overId));
    setItemIds(idsArrayMove(itemIds, activeId, overId));
  };

  const setItemsOnCollapse = (folderId: number, toggletype?: "open" | "close") => {
    const children = routes.filter((item) => item.parentId === folderId);
    let newItems = [];
    if (isOpen(folderId) || toggletype === "close") {
      setClose(folderId);
      newItems = items.filter((item) => routes.find((route) => route.id === item.id)?.parentId !== folderId);
    } else {
      setOpen(folderId);
      const i = items.findIndex((item) => item.id === folderId) + 1;
      newItems = [...items.slice(0, i), ...children, ...items.slice(i)];
    }
    setItemIds(newItems.map((item) => item.id));
  };

  // return { items, setItemsOnDragEnd, setItemsOnCollapse };
  return {
    items,
    setItem,
    // setItems,
    setItemsOnDragEnd,
    setItemsOnCollapse,
  };
};

const leftNavOpenFoldersState = atom<number[]>({
  key: "leftNavOpenFoldersState",
  default: [],
});

export const useLeftNavOpenFolders = () => {
  const setState = useSetRecoilState(leftNavOpenFoldersState);
  const openIds = useRecoilValue(leftNavOpenFoldersState);
  // const { setItemsOnCollapse } = useLeftNavItems();
  const setOpen = (openId: number) => {
    const ids = [...openIds];
    ids.push(openId);
    setState(ids);
    // setItemsOnCollapse(openId);
  };
  const setClose = (closeId: number) => {
    setState(openIds.filter((id) => id !== closeId));
    // setItemsOnCollapse(closeId);
  };
  const isOpen = (id: number) => openIds.includes(id);

  return { openIds, setOpen, setClose, isOpen };
};
