import React, { useState } from "react";
import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext } from "@dnd-kit/sortable";
import { useLeftNavItems, useLeftNavOpenFolders } from "globalStates/leftNavState";
import { List } from "@mui/material";
import { TRoute } from "models/RouteModel";
import { TMenu } from "models/MenuModel";
import DndLeftNavMenuTitle from "./DndLeftNavMenuTitle";
import DndLeftNavItemSortable from "./DndLeftNavItemSortable";
import DndLeftNavItem from "./DndLeftNavItem";

const LeftNavMenu = () => {
  const { items, setItem, setItemsOnDragEnd, setItemsOnCollapse } = useLeftNavItems();
  const [activeId, setActiveId] = useState<number | null>(null);
  const activeItem = items.find((item) => item.id === activeId);
  const { isOpen: isOpenParent } = useLeftNavOpenFolders();

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={(event) => {
        const { active } = event;
        setActiveId(active.id as number);
        if (items.find((route) => route.id === active.id)?.type === "folder") {
          setItemsOnCollapse(active.id as number, "close");
        }
      }}
      onDragEnd={(event) => {
        setActiveId(null);
        const { active, over } = event;
        active && over && active.id !== over.id && setItemsOnDragEnd(active.id as number, over.id as number);
      }}
      onDragOver={(event) => {
        const { active, over } = event;
        if (active && over) {
          const activeItem = { ...items.find((item) => item.id === active.id) } as TRoute;
          const overItem = items.find((item) => item.id === over.id) as TRoute;
          if (activeItem && overItem) {
            if (
              overItem?.depth ||
              (overItem?.type === "folder" &&
                isOpenParent(overItem.id) &&
                active.data.current?.sortable.index < over.data.current?.sortable.index)
            ) {
              activeItem["depth"] = 1;
              activeItem["parentId"] = overItem.type === "folder" ? overItem.id : overItem.parentId;
            } else {
              activeItem["depth"] = 0;
              activeItem["parentId"] = undefined;
            }
            setItem(activeItem);
          }
        }
      }}
    >
      <SortableContext items={items}>
        <List component="nav" dense={true}>
          {items.map((item) => {
            const id = `${item.type}_${item.id}`;
            switch (item.type) {
              case "menu":
                return <DndLeftNavMenuTitle key={id} item={item as TMenu} />;
              case "folder":
              case "route":
                return <DndLeftNavItemSortable key={id} item={item as TRoute} />;
              default:
                return false;
            }
          })}
        </List>
      </SortableContext>
      <DragOverlay>{activeItem && <DndLeftNavItem item={activeItem as TRoute} />}</DragOverlay>
    </DndContext>
  );

  // return (
  //   <>
  //     {menus.map((menu: TMenu) => {
  //       return (
  //         <React.Fragment key={menu.id}>
  //           <List
  //             component="nav"
  //             aria-labelledby={`menu_${menu.id}`}
  //             subheader={
  //               <ListSubheader component="div" id={`menu_${menu.id}`}>
  //                 {menu.title}
  //               </ListSubheader>
  //             }
  //           >
  //             {routesTree
  //               ?.filter((item: TRoute) => item.menuId === menu.id)
  //               ?.map((item: TRoute) => {
  //                 return <LeftNavItem item={item} key={item.id} />;
  //               })}
  //           </List>
  //           <Divider />
  //         </React.Fragment>
  //       );
  //     })}
  //   </>
  // );
};

export default LeftNavMenu;
